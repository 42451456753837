<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../assets/problemBg.jpg");
  background-size: 100% 100%;
}
.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  position: relative;
}
.header .back img {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16pt;
  height: 16pt;
}
.header .btn {
  position: absolute;
  top: 28px;
  right: 20px;
  line-height: 23px;
  font-size: 20px;
}
.middle {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}
.bodyScroll {
  width: 100%;

  height: calc(100vh - 120px);
  overflow-y: scroll;
}
.body {
  width: 90%;
  /* margin: 3vh 5% 0; */
  /* height: 77vh; */
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
  overflow: hidden;
}
.body .problemHeader {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.body .problemHeader .problemIndex {
  width: 100%;
  height: 50px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #fafafa;
  text-align: left;
  padding: 10px;
}
.body .problemHeader .problemIndex > div {
  display: inline-block;
  vertical-align: top;
}
.body .problemHeader .problemIndex > div:first-child {
  width: 3px;
  height: 20px;
  margin-top: 4px;
  border: 2px solid rgb(169, 151, 141);
}
.body .problemHeader .problemIndex > div:nth-child(2) {
  margin-left: 7px;
}
.body .problemHeader .problemIndex > div:nth-child(3) {
  float: right;
  margin-right: 10px;
}
.body .problemHeader .problemTitle {
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-align: left;
}
.problemChooseBox {
  width: 100%;
  padding: 0 15px;
  overflow-y: scroll;
}
.problemChooseBox .chooseLine {
  width: 100%;
  padding: 6px 15px;
  background: rgb(247, 249, 251);
  border: 1px solid rgb(241, 241, 244);
  color: rgb(90, 96, 99);
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  letter-spacing: 2px;
  margin: 10px 0;
  font-size: 16px;
  /* text-overflow: ellipsis;  */
  /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  /* white-space: nowrap; */
  /*让文字不换行*/
  /* overflow: hidden;  */
  /*超出要隐藏*/
  transition: 0.2s;
}
.companyAndSource {
  width: 100%;
  padding: 15px;
  text-align: left;
}
.companyAndSource > div > div {
  /* display: inline-block; */
  color: rgb(41, 151, 78);
  background: rgb(223, 244, 228);
  padding: 3px 7px;
  margin: 5px 0;
  font-size: 13px;
}
.answerTip {
  color: rgb(169, 151, 141);
  width: 100px;
  float: right;
  font-size: 15px;
  margin: 10px 0;
}
</style>
<style scoped>
.lineErr {
  border: 1px solid #f00 !important;
  color: #f00;
}
.lineSuccess {
  border: 1px solid rgb(65, 206, 65) !important;
  color: rgb(65, 206, 65);
}
.lineChoose {
  border: 1px solid #f60 !important;
  color: #f60;
}
.chooseQues {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(74, 74, 75, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.chooseQues .card {
  width: 300px;
  /* height: 150px; */
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
}
.chooseQues input {
  outline: none;
  border: 1px solid #efefef;
  text-align: center;
  height: 40px;
}
.chooseQues .btn {
  margin-top: 30px;
}
.title {
  /*超出部分隐藏*/
  overflow: hidden;
  /*强制在一行*/
  white-space: nowrap;
  /*文本超出部分省略*/
  text-overflow: ellipsis;
  width: 60%;
  justify-content: center;
}
textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: transparent;
  font-size: inherit;
  width: 100%;
}

textarea:focus {
  outline: none !important;
}

.mo-textarea {
  height: 100px;
  display: inline-block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  color: #606266;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #3677f0;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/* 提示文字 */
.mo-textarea::placeholder {
  color: #c0c4cc;
}

/* 鼠标hover */
.mo-textarea:hover {
  border-color: #c0c4cc;
}

/* 获得焦点 */
.mo-textarea:focus {
  border-color: #0059ff;
}
</style>
<template>
  <div class="home">
    <!-- 头部信息 -->
    <div class="header">
      <div class="title">
        {{ activity.name }}
      </div>
      <div class="back" @click="chooseQues">
        <div class="middle"></div>
        <img @click="back" src="../assets/back.png" alt="" />
      </div>
      <div v-if="index != allProblem || bottomStatus == 'button'" class="btn">
        <div class="middle"></div>
        <span v-if="bottomStatus == 'button'" @click="confirm">确定</span>
        <span v-if="bottomStatus !== 'button'" @click="nextProblem"
          >下一题</span
        >
      </div>
    </div>
    <div class="bodyScroll">
      <!-- 答题框信息 -->
      <div class="body">
        <div class="problemHeader">
          <div class="problemIndex">
            <div></div>
            <div>
              <span v-if="type == 'select'">单选题</span>
              <span v-if="type == 'multi'">多选题</span>
              <span v-if="type == 'check'">判断题</span>
              <span v-if="type == 'blank'">填空题</span>
            </div>
            <div>
              <span style="font-size: 30px">{{ index }}</span>
              <span style="color: #ccc">/{{ allProblem }}</span>
            </div>
          </div>
          <div class="problemTitle">
            <div class="middle"></div>
            <div style="display: inline-block">
              {{ problem.problem }}
            </div>
          </div>
          <div class="problemChooseBox">
            <div
              @click="choose(index)"
              v-for="(item, index) in problem.choose"
              :key="index"
              :class="chooseStyle[index]"
            >
              <span v-if="problem.type == 'select' || problem.type == 'multi'">
                {{ chooseNum[index] }}.</span
              >{{ item }}
            </div>
            <div v-if="problem.type == 'blank'">
              <textarea
                class="mo-textarea"
                placeholder="请输入内容"
                v-model="blankAnswer"
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div
            v-if="
              (problem.company && problem.company !== '') ||
              (problem.source && problem.source !== '')
            "
            class="companyAndSource"
          >
            <div v-if="problem.company && problem.company !== ''">
              <div style="display: inline-block">
                出题单位：{{ problem.company }}
              </div>
            </div>
            <div v-if="problem.source && problem.source !== ''">
              <div style="display: inline-block">
                题目来源：{{ problem.source }}
              </div>
            </div>
          </div>
          <!-- <div class="answerTip">
            <i
              style="margin-right:5px"
              class="fa fa-lightbulb-o"
              aria-hidden="true"
            ></i
            >查看提示
          </div> -->
        </div>
      </div>
      <div
        v-if="bottomStatus !== 'button'"
        class="body"
        style="margin-top: 20px"
      >
        <div class="problemHeader">
          <div class="problemIndex">
            <div></div>
            <div>
              <!-- <span>答案解析</span> -->
              <span>正确答案:&nbsp;{{ correctAnswer }}</span>
            </div>
          </div>
          <!-- <div style="width:100%;text-align:left;padding:15px">
            <h3>正确答案:{{ correctAnswer }}</h3>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 选择题目框输入框 -->
    <div class="chooseQues" v-show="dialog">
      <div class="card">
        <p>跳转至</p>
        <input type="text" v-model="quesNum" />
        <div class="btn">
          <!-- <v-btn depressed @click="cancelChoose">
            取消
          </v-btn>
          <v-btn
            depressed
            color="green"
            @click="sureChoose"
          >确定</v-btn> -->

          <Button @click="cancelChoose" style="margin-right: 10px">
            取消
          </Button>
          <Button type="info" @click="sureChoose"> 确定 </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData"; // Data request API
export default {
  name: "answerDetailExercise",
  data () {
    return {
      dialog: false,
      quesNum: 1,
      activityId: "",
      problems: [],
      problem: {},
      index: 1, // 正在作答的题目的顺序
      allProblem: 0, // 题目总数
      type: "over", // 正在作答的题目类型
      chooseStyle: [], // 选项的页面格式
      chooseNum: ["A", "B", "C", "D", "E", "F"], // 题目序列号
      selectAnswer: "", // 单选题答案
      multiAnswer: [], // 多选题答案
      checkAnswer: "", // 判断题答案
      blankAnswer: "", // 填空题答案
      activity: {}, // 正在参与的活动
      bottomStatus: "button", // "result" | "button",
      correctAnswer: "",
      correctStatus: false,
      currectPage: 1, // 分页
    };
  },
  components: {},
  methods: {
    // 取消
    cancelChoose () {
      this.dialog = false;
    },
    // 确定
    async sureChoose () {
      if (this.quesNum > this.allProblem || this.quesNum <= 0) {
        return;
      }
      const result = await wxConPost(
        "/services/gttt-answer-exam/exam/getQuestionPage",
        {
          qBankName: this.activity.qBankNameArr,
          limit: 10,
          skip: this.quesNum - 1,
        }
      );
      // 10道题，那么过滤前9个，前9个也要填充为0，取10的索引为9
      this.problems = new Array(this.quesNum - 1).fill(0);
      this.problems = this.problems.concat(...result.ret);
      this.index = this.quesNum;
      this.problem = this.problems[this.index - 1];
      this.type = this.problem.type;
      const mewChooseStyle = [];
      if (this.problem.choose) {
        for (const item of this.problem.choose) {
          mewChooseStyle.push("chooseLine");
        }
      }
      this.dialog = false;
    },
    // 选择指定题目
    chooseQues () {
      this.dialog = true;
    },
    // 确认当前答题
    async confirm () {

      // 判断题型  选择题判断是否选中了答案  填空题判断填空中是否有字符串
      await this.checkProblem();
      this.correctAnswer = this.problem.answer.join("、");
      this.bottomStatus = "result";
    },
    // 下一题
    async nextProblem () {

      this.bottomStatus = "button";
      if (this.index == this.problems.length) {
        // this.initProb();
      } else {

        this.index++;

        this.problem = this.problems[this.index - 1];
        this.type = this.problem.type;
        const mewChooseStyle = [];

        if (this.problem.choose) {
          for (const item of this.problem.choose) {
            // console.log(this.problem.choose);
            mewChooseStyle.push("chooseLine");
          }
        }
        this.chooseStyle = mewChooseStyle;
        // 每10道题查询一次   剩余最后一道题时查询一次
        if (this.index % 10 == 9) {
          // if (this.allProblem > this.index + 1) {
          //   this.initProb();
          // }
          // hzj修改
          if (this.allProblem > this.index) {
            this.initProb();
          }
        }
      }
    },
    // 判断题目进行加分
    async checkProblem () {
      if (this.problem.type == "select") {
        // 将选中的答案变成绿色
        const index = this.chooseNum.indexOf(this.problem.answer[0]);
        if (index !== -1) {
          this.chooseStyle[index] = "chooseLine lineSuccess";
        }
        // 单选题判断数组第一个即可
        if (this.selectAnswer == this.problem.answer[0]) {
          this.correctStatus = true;
        } else {
          this.correctStatus = false;
        }
      } else if (this.problem.type == "multi") {
        // 多选题判断  首先判断答案位数是否一样
        if (this.problem.answer.length == this.multiAnswer.length) {
          // 一致时先进行排序，然后进行比较
          const newAnswer = this.problem.answer.sort();
          const newDoAnswer = this.multiAnswer.sort();
          if (newAnswer.join("") == newDoAnswer.join("")) {
            this.correctStatus = true;
          } else {
            this.correctStatus = false;
          }
        } else {
          this.correctStatus = false;
        }
        // 正确答案变成绿色
        for (const index in this.problem.choose) {
          if (this.problem.answer.indexOf(this.chooseNum[index]) !== -1) {
            this.chooseStyle[index] = "chooseLine lineSuccess";
          }
        }
      } else if (this.problem.type == "check") {
        const index = this.problem.choose.indexOf(this.problem.answer[0]);
        if (index !== -1) {
          this.chooseStyle[index] = "chooseLine lineSuccess";
        }
        // 判断题直接判断答案即可
        if (this.checkAnswer == this.problem.answer[0]) {
          this.correctStatus = true;
        } else {
          this.correctStatus = false;
        }
      } else if (this.problem.type == "blank") {
        // 填空题直接判断答案即可
        if (this.blankAnswer.trim() == this.problem.answer[0]) {
          this.allResult = this.allResult + parseInt(this.problem.score);
        }
        // hzj新增
        // else {
        //   this.wrongTopics.push(this.problem);
        //   this.wrongSelect.push(this.checkAnswer);
        // }
      }
    },
    // 返回上一页
    back () {
      this.$router.replace("/activity-main");
    },
    // 选择题答案(选项序号，选项)
    choose (answerIndex) {
      // 如果为单选题
      if (this.problem.type == "select") {
        const newChooseStyle = [];
        for (const index in this.chooseStyle) {
          if (answerIndex == index) {
            newChooseStyle.push("chooseLine lineChoose");
          } else {
            newChooseStyle.push("chooseLine");
          }
        }
        this.chooseStyle = newChooseStyle;
        this.selectAnswer = this.chooseNum[answerIndex];
      } else if (this.problem.type == "multi") {
        // 如果为多选题
        const index = _.indexOf(this.multiAnswer, this.chooseNum[answerIndex]);
        if (index !== -1) {
          this.multiAnswer.splice(index, 1);
          this.chooseStyle[answerIndex] = "chooseLine";
        } else {
          this.multiAnswer.push(this.chooseNum[answerIndex]);
          this.chooseStyle[answerIndex] = "chooseLine lineChoose";
        }
        this.$forceUpdate();
      } else if (this.problem.type == "check") {
        // 如果为判断题
        const newChooseStyle = [];
        for (const index in this.chooseStyle) {
          if (answerIndex == index) {
            newChooseStyle.push("chooseLine lineChoose");
          } else {
            newChooseStyle.push("chooseLine");
          }
        }
        this.chooseStyle = newChooseStyle;
        this.checkAnswer = this.problem.choose[answerIndex];
      } else if (this.problem.type == "blank") {
        // 如果为判断题
        this.blankAnswer = this.problem.choose[answerIndex];
      }
    },
    // initProb  init初始状态
    async initProb (init) {
      const result = await wxConPost(
        "/services/gttt-answer-exam/exam/getQuestionPage",
        {
          qBankName: this.activity.qBankNameArr,
          limit: 10,
          skip: this.problems.length,
        }
      );
      if (result.ret) {
        // 去查接口  返回一组题目
        // this.problems = result.ret;
        this.problems = this.problems.concat(result.ret);
        if (init == true && this.problems.length > 0) {
          // 如果为第一次查询需选择第一个  之后的只需
          if (this.currectPage == 1) {
            this.problem = this.problems[0];
            this.index = 1;
            this.chooseStyle = [];
            this.type = this.problem.type;
            if (this.problem.choose) {
              for (const item of this.problem.choose) {
                this.chooseStyle.push("chooseLine");
              }
            }
          }
        }
        // 题目总数
        this.allProblem = result.count;
      }
    },
  },
  watch: {
    index (val) {
      this.quesNum = val;
    },
  },
  async created () {
    this.activity = await globalData.getActivity();
    if (!_.isEmpty(this.activity)) {
      this.activityId = this.activity.id;
      await this.initProb(true);
    } else {
      this.back();
    }
  },
};
</script>
